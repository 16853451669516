<template>
  <div id="debug">
    <span class="title">ROUTE</span><br>
    STATUS: {{data.route.status}}<br>
    <span class="title">REROUTE</span><br>
    STATUS: {{data.route.reroute.status}}<br>
    COUNT: {{data.route.reroute.count}}<br>
    <span class="title">PATH</span><br>
    LENGTH: {{data.route.coords.length}}<br>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style scoped>
  #debug {
    position: absolute;
    bottom: 25px;
    right: 15px;
    z-index: 200;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    box-sizing: border-box;
    font-size: 10px;
    min-width: 275px;
  }
  .title {
    font-weight: bold;
    font-size: 12px;
  }
</style>