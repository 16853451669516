<template>
  <div id="404">
    <div class="container">
      <h1>404</h1>
      <h3>{{message}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Página não encontrada!'
    }
  }
}
</script>

<style scoped>
  #404 {
    display: flex;
    align-items: center;
  }

  .container {
    text-align: center;
  }

  h1 {
    font-size: 150px;
    color: #201E1E;
    margin: 0;
  }

  h3 {
    font-size: 25px;
    font-weight: normal;
    color: #201E1E;
    margin: 0;
  }
</style>


