import { Console } from "winston/lib/winston/transports"

export default {
  CircleStart: {
    color: '#201E1E',
    strokeColor: '#201E1E',
    fillColor: '#249B88',
    fillOpacity: 1,
    strokeWeight: 1,
    weight: 0.5,
    radius: 5
  },
  CircleEnd: {
    radius: 5,
    fillColor: '#EA1E63',
    fillOpacity: 1,
    color: '#201E1E',
    strokeColor: '#201E1E',
    weight: 0.5,
    strokeWeight: 0.1
  },
  CircleDestiny: {
    radius: 5,
    fillColor: '#565cff',
    fillOpacity: 1,
    color: '#201E1E',
    weight: 0.5,
    strokeColor: '#201E1E',
    strokeWeight: 1
  },
  CircleAccuracy (accuracy) {
    return {
      color: accuracy > 1 ? '#72d665' : '#EA1E63',
      radius: accuracy,
      fillColor: accuracy > 1 ? '#72d265' : '#EA1E63',
      fillOpacity: .35,
      stroke:false
    }
  },
  PointAccuracy () {
    return {
      color: '#201E1E',
      radius: 0.3,
      fillColor: '#201E1E',
      fillOpacity: .35,
      stroke:false
    }
  },
  DefaultLeaflet: {
    "type": "FeatureCollection",
    "features": []
  },
  DirectionsTrack: {
    "type": "Feature",
    "geometry": {
      "type": "MultiLineString",
    },
    "properties": {
      "track": true
    }
  },
  DirectionsTrajeto: {
    "type": "Feature",
    "geometry": {
      "type": "MultiLineString",
    },
    "properties": {
      "track": false
    }
  },
  Distance: {
    get(origin, destination) {
      var lon1 = this.toRadian(origin[1]),
          lat1 = this.toRadian(origin[0]),
          lon2 = this.toRadian(destination[1]),
          lat2 = this.toRadian(destination[0]);
  
      var deltaLat = lat2 - lat1;
      var deltaLon = lon2 - lon1;
  
      var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    toRadian(degree) {
        return degree*Math.PI/180;
    }
  },
}
