<template>
  <div id="compartilhar-info">
    <div class="card passageiro">
      <div class="inner">
        <span class="nome">{{ corrida.passageiro.nome }}</span>
        <span class="status">{{ statusCorrida }}</span>
        <span class="tempo" v-show="showDuration">{{ duration | duration }}</span>
      </div>
    </div>
    <div class="card motorista">
      <div class="inner">
        <div class="img-container">
          <img class="picture" :src="corrida.motorista.foto" alt="foto">
        </div>
        <div>
          <div class="info">
            <span class="nome">{{ corrida.motorista.nome }}</span>
            <span class="avaliacao">{{ corrida.motorista.avaliacao }}</span>
            <img class="star" src="../assets/svg/star.svg" alt="">
          </div>
          <div class="carro">
            <span class="marca">{{ corrida.motorista.carro.marca | trunc(12) }}</span>
            <span class="modelo">{{ corrida.motorista.carro.modelo | trunc(11) }}</span>
            <span class="placa">{{ corrida.motorista.carro.placa }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../assets/img/logo.png'

export default {
  props: {
    status: {
      type: Number
    },
    duration: {
      type: Number
    },
    corrida: {
      type: Object,
      required: true
    }
  },
  computed: {
    showDuration() {
      return this.duration && this.status === 30
    },
    statusCorrida() {
      if (this.status !== 30) {
        return 'finalizou a corrida'
      } else {
        return 'está em deslocamento'
      }
    }
  },
  created() {
    if (!this.corrida.motorista.foto) {
      this.corrida.motorista.foto = logo
    }
  }
}
</script>

<style scoped>
  #compartilhar-info {
    position: absolute;
    color: #201E1E;
    width: 100%;
    top: 10px;
    max-width: 400px;
    z-index: 999;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .card {
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px #3F3F3F;
    background-color: #FFF;
    align-items: center;
    display: flex;
  }

  .passageiro {
    height: 45px;
    margin-bottom: 5px;
    padding: 0 15px;
  }

  .passageiro .inner {
    display: flex;
    align-items: baseline;
    width: 100%;
  }

  .passageiro .nome {
    font-size: 18px;
    color: #4B4B4D;
  }

  .passageiro .status {
    font-size: 14px;
    margin-left: 10px;
    color: #9A9A9C;
  }

  .passageiro .tempo {
    flex-grow: 1;
    text-align: right;
    color: #4B4B4D;
    font-size: 15px;
  }

  .carro {
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #4B4B4D;
  }

  .carro .marca {
    margin-right: 5px;
  }

  .placa {
    font-size: 12px;
    background-color: #F0F0F0;
    color: #107263;
    border-radius: 4px;
    font-weight: bold;
    padding: 2px 5px;
    margin-left: 10px;
  }

  .info .nome {
    margin-right: 10px;
    font-weight: bold;
    color: #18BC9C;
  }

  .info .avaliacao {
    font-size: 12px;
    font-weight: bold;
  }

  .info .star {
    width: 13px;
    display: inline-block;
    margin-left: 2px;
  }

  .motorista {
    height: 75px;
    padding: 0 15px;
  }

  .motorista .inner {
    display: flex;
    align-items: center;
  }

  .motorista .img-container {
    margin-right: 15px;
  }

  .motorista .picture {
    width: 45px;
    border-radius: 50%;
    display: block;
  }

  .motorista .info {
    display: flex;
    align-items: center;
  }
</style>


