import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import Compartilhar from './components/Compartilhar.vue'
import DetalheCorrida from './components/DetalheCorrida'
import NotFound from './components/NotFound.vue'
import './filters.js'
import 'leaflet-rotatedmarker/leaflet.rotatedMarker';

import './assets/css/main.css'

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.use(VueRouter)

Vue.prototype.$debug = process.env.VUE_APP_DEBUG === 'true'

Vue.http.interceptors.push(
  (request) => {
    if (process.env.NODE_ENV === 'development') {
      request.url = `${process.env.VUE_APP_API_URL}${request.url}`
    }
  }
)

const routes = [
  { path: '/detalhe/:uid_corrida', component: DetalheCorrida },
  { path: '/compartilhar/:uid_corrida', component: Compartilhar },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router
}).$mount('#app')
