<template>
  <div id="container">
    <div id="mapContainer"></div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import maps from '../utils/maps'

export default {
  components: {},
  data() {
    return {
      Map: {},
      partida: { lat: 0, lng: 0 },
      destino: { lat: 0, lng: 0 },
      final: { lat: 0, lng: 0 },
      circles: {
        destino: null
      },
      info_corrida: {},
      data: [],
      map: null
    };
  },
  methods: {
    async init() {
      await this.fetchData()
    },
    styleMap(feature){
      const track = feature.properties.track
      const color = track == true ? '#837cff' : '#ff4d44';
      return { color: color, opacity: 0.7 };
    },
    async fetchData() {
      const uid_corrida = this.$route.params.uid_corrida
      try {
        const  { data, status } = await this.$http.get(`/api/corrida/${uid_corrida}`)
        if (status === 200) {
          if (data) {
            this.partida = { lat: parseFloat(data.lat_partida), lng: parseFloat(data.lng_partida) }
            this.destino = { lat: parseFloat(data.lat_destino), lng: parseFloat(data.lng_destino) }
            this.final = { lat: parseFloat(data.lat_final), lng: parseFloat(data.lng_final) }
            this.info_corrida = data.info_corrida
            this.setupLeafletMap()
          }
        }
      } catch (err) {
        console.error(err)
      }
    },
    setupTrack() {
      let trackJson = maps.DirectionsTrack
      const track = this.info_corrida.track
      let coordinates = []
      var  markerBounds = new L.FeatureGroup();
      for (let data of track.data) {
        coordinates.push([data.lng, data.lat])
        let circle = L.circle([data.lat,data.lng], maps.CircleAccuracy(data.accuracy ? data.accuracy : 1));
        L.circle([data.lat,data.lng], maps.PointAccuracy(data.accuracy ? data.accuracy : 1)).addTo(this.map);
        markerBounds.addLayer(circle);
      }
      if (this.circles.destino) {
        markerBounds.addLayer(this.circles.destino)
      }
      markerBounds.addTo(this.map);
      this.map.fitBounds(markerBounds.getBounds());
      trackJson.geometry.coordinates = [coordinates]
      return trackJson
    },
    setupTrajeto() {
      let trajetoJson = maps.DirectionsTrajeto
      const trajeto = this.info_corrida.trajeto.split('|')
      let coordinates = []
      for (let data of trajeto) {
        const [lat, lng] = data.split(',')
        if (lat != '' && lng != '') {
          coordinates.push([parseFloat(lng),parseFloat(lat)])
        }
      }
      trajetoJson.geometry.coordinates = [coordinates]
      return trajetoJson
    },
    setupRoutes() {
      let defaultJson = maps.DefaultLeaflet
      const trajeto = this.setupTrajeto()
      const route = this.setupTrack()
      defaultJson.features.push(trajeto)
      defaultJson.features.push(route)
      return defaultJson
    },
    setupMarkers() {
      L.circle([this.partida.lat, this.partida.lng], maps.CircleStart).addTo(this.map);
      L.circle([this.final.lat, this.final.lng], maps.CircleEnd).addTo(this.map);
      this.circles.destino = L.circle([this.destino.lat, this.destino.lng], maps.CircleDestiny).addTo(this.map);
      this.setupTrack()
    },
    setupLeafletMap () {
      this.map = L.map("mapContainer").setView([this.partida.lat, this.final.lng], 18);
      L.tileLayer(
        "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
        {
          maxZoom: 22,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }
      ).addTo(this.map);
      L.geoJSON(this.setupRoutes() , {
        style: this.styleMap
      })
      .addTo(this.map)
      this.setupMarkers();
    }
  },
  async created() {
    await this.init();
  }
};
</script>
<style scoped>
#mapContainer {
  width: 100vw;
  height: 100vh;
}
</style>