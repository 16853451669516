import Vue from 'vue'

Vue.filter('trunc', (value, length) => {
  return value.length > (length + 3) ? `${value.substring(0, length)}...` : value
})

Vue.filter('duration', value => {
  let duration = Math.round(value)

  if (duration < 2) {
    return `1 min`
  }

  return `${duration} mins` 
})