import firebase from 'firebase/app'
import "firebase/database";

const project = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
})

export default {
  track(uid_motorista, callback) {
    const ref = this.getRef(uid_motorista)

    ref.on('value', callback)

    return ref
  },

  getRef(uid_motorista) {
    return project.database().ref().child(`motoGeo/${uid_motorista}/l`)
  },

  untrack(ref) {
    if (ref) {
      ref.off('value')
    }
  }
}