<template>
  <div v-if="page.status === constants.Page.NOT_FOUND" class="not-found">
    <not-found></not-found>
  </div>
  <div v-else class="container">
    <div id="map-container">
      <compartilhar-info :status="status" :corrida="corrida" :duration="duration" v-if="corrida && info.show"></compartilhar-info>
      <compartilhar-debug :data="{ route }" v-if="$debug"></compartilhar-debug>
      <div id="map" ref="map"></div>
    </div>
  </div>
</template>

<script>
import CompartilharInfo from './CompartilharInfo'
import CompartilharDebug from './CompartilharDebug'
import NotFound from './NotFound'
import tracker from '../utils/tracker'
import { getEnv } from '../utils/config'
import { clearTimeout, clearInterval, setInterval, setTimeout } from 'timers'
import Helpers from '../utils/helpers/Helpers'
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import maps from '../utils/maps'

export default {
  components: {
    CompartilharInfo, CompartilharDebug, NotFound
  },
  data() {
    return {
      constants: {
        Page: {
          NOT_FOUND: 'NOT_FOUND'
        },
        Reroute: {
          SHOULD_REROUTE: 'SHOULD_REROUTE',
          KEEP_CURRENT_ROUTE: 'KEEP_CURRENT_ROUTE'
        },
        Route: {
          ON_ROUTE: 'ON_ROUTE',
          OFF_ROUTE: 'OFF_ROUTE'
        }
      },
      map: null,
      marker: {
        motorista: null,
        destino: null,
        partida: null,
        final: null
      },
      motorista: {
        position: {
          curr: {
            lat: 0,
            lng: 0
          },
          prev: null
        }
      },
      destino: {
        position: {}
      },
      partida: {
        position: {}
      },
      final: {
        position: {}
      },
      route: {
        path: null,
        coords: [],
        reroute: {
          count: 0,
          status: null
        },
        status: null,
        off: {
          timer: null,
        }
      },
      response: null,
      info: {
        show: false
      },
      tracker: null,
      corrida: null,
      status: null,
      duration: null,
      page: {
        status: null
      },
      poll: {
        status: {
          reference: null,
          time: 30000
        }
      },
      config: {}
    }
  },
  computed: {
    trajeto() {
      if (this.corrida.info_corrida) {
        const trajeto = Helpers.handlePath(this.corrida.info_corrida.trajeto);
  
        return trajeto.split('|').map((coord) => {
          const [lat, lng] = coord.split(',')
  
          return { lat: parseFloat(lat), lng: parseFloat(lng) }
        })
      } else {
        return []
      }
    }
  },
  methods: {
    init(data) {
      const status = data.status

      this.corrida = data
      this.status = status

      this.partida.position = { lat: +data.lat_partida, lng: +data.lng_partida }
      this.destino.position = { lat: +data.lat_destino, lng: +data.lng_destino }
      this.final.position = { lat: +data.lat_final, lng: +data.lng_final }

      if (status === 30) {
        this.startTracking(data.motorista.uid)
      } else if (status === 35) {
        this.drawTrack()
      } else {
        console.warn('status', status)
      }
    },
    async fetchData() {
      const uid_corrida = this.$route.params.uid_corrida

      try {
        const { status, data } = await this.$http.get(`/api/corrida/${uid_corrida}`)

        if (status === 200) {
          if (data) {
            this.init(data)
          } else {
            this.page.status = this.constants.Page.NOT_FOUND
          }
        } else {
          console.warn('status', status)
        }
      } catch (err) {
        console.error(err)
      }
    },
    calculateBearing(lat1, lon1, lat2, lon2) {
      const radLat1 = this.toRadians(lat1);
      const radLon1 = this.toRadians(lon1);
      const radLat2 = this.toRadians(lat2);
      const radLon2 = this.toRadians(lon2);

      const deltaLon = radLon2 - radLon1;
      const y = Math.sin(deltaLon) * Math.cos(radLat2);
      const x = Math.cos(radLat1) * Math.sin(radLat2) - Math.sin(radLat1) * Math.cos(radLat2) * Math.cos(deltaLon);
      const bearing = this.toDegrees(Math.atan2(y, x));

      return (bearing + 360) % 360;
    },

    toRadians(degrees) {
      return (parseFloat(degrees) * Math.PI) / 180;
    },

    toDegrees(radians) {
      return (radians * 180) / Math.PI;
    },
    calcDuration() {
      const distance = maps.Distance.get([
        this.motorista.position.curr.lat,
        this.motorista.position.curr.lng],
        [this.destino.position.lat,
        this.destino.position.lng]
      )

      //Assumindo uma velocidade de 500 metros por minuto para o motorista
      this.duration = distance / 500
    },
    startTracking(uid_motorista) {
      this.setupLeafletMap(this.partida.position);
      this.setupMarkers();

      const bounds = [].concat(
        this.partida.position,
        this.destino.position
      )
      this.map.fitBounds(bounds);
      this.tracker = tracker.track(uid_motorista, (snapshot) => {
        const p = snapshot.val()

        if (p) {
          this.motorista.position.curr = { lat: p[0], lng: p[1] }

          if (!this.marker.motorista) {

            this.marker.motorista = this.setPlaceMotorista(
              this.motorista.position.curr.lat,
              this.motorista.position.curr.lng,
            )

            this.route.reroute.status = this.constants.Reroute.KEEP_CURRENT_ROUTE

            this.setRoute((data) => {
              this.route.coords = data
              this.route.path = this.drawRoute(this.route.coords)

              this.startStatusPolling()
            })
          }

          if (this.motorista.position.prev) {
            this.updateMotoristaPosition(this.motorista.position.curr.lat, this.motorista.position.curr.lng)

            this.calcDuration()

            this.updateRoute()

            this.map.flyTo([
              this.motorista.position.curr.lat,
              this.motorista.position.curr.lng
            ])
          }

          this.motorista.position.prev = {
            lat: this.motorista.position.curr.lat,
            lng: this.motorista.position.curr.lng
          }
        }
      })
    },
    updateMotoristaPosition(lat, lng) {
      this.marker.motorista.setLatLng(
          L.latLng(lat, lng),
        );
        const angle = this.calculateBearing(this.motorista.position.prev.lat, this.motorista.position.prev.lng, lat, lng);//*
        this.marker.motorista.setRotationOrigin('center');
        this.marker.motorista.setRotationAngle(parseInt(angle));
        [this.motorista.position.prev.lat, this.motorista.position.prev.lng] = [lat, lng];
    },
    async setConfig() {
      const { status, body } = await getEnv()

      if (status === 200) {
        this.config.rerouteTime = body.rerouteTime
        this.config.useGoogleMapsAPI = body.useGoogleMapsAPI
        this.config.offRouteTolerance = body.offRouteTolerance
      }
    },
    async setRoute(cb) {
      if (this.config.useGoogleMapsAPI) {
        maps.Directions.setRoute(
          this.motorista.position.curr.lat,
          this.motorista.position.curr.lng,
          this.destino.position.lat,
          this.destino.position.lng,
          this.map,

          (status) => console.error(status),
          (status, response) => {
            const path = response.routes[0].overview_path
            const coords = []

            path.forEach((val, index) => {
              coords.push({ lat: val.lat(), lng: val.lng() })
            })

            cb(coords)
          })
      } else {
        const uid_corrida = this.$route.params.uid_corrida

        try {
          const { status, data } = await this.$http.get(`/api/direction/${uid_corrida}`)

          if (status === 200) {
            if (data) {
              cb(data)
            } else {
              console.warn(status, data)
            }
          } else {
            console.error(status, data)
            cb([])
          }
        } catch (err) {
          console.log(err)
          cb([])
        }
      }
    },
    styleMap(feature){
      const track = feature.properties.track
      const color = track == true ? '#837cff' : '#18BC9C';
      return { color: color, opacity: 0.7 };
    },
    setupLeafletMap (position) {
      this.map = L.map("map-container", { zoomControl: false }).setView([position.lat, position.lng], 18);
      L.tileLayer(
        "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
        {
          maxZoom: 22,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }
      ).addTo(this.map);
        L.geoJSON(this.setupRoutes() , {
          style: this.styleMap
        }).addTo(this.map)
      return this.map
    },
    setupTrajeto() {
      let trajetoJson = maps.DirectionsTrajeto
      const trajeto = this.trajeto
      let coordinates = []
      for (let data of trajeto) {
        if (data.lat != '' && data.lng != '') {
          coordinates.push([parseFloat(data.lng),parseFloat(data.lat)])
        }
      }
      trajetoJson.geometry.coordinates = [coordinates]
      return trajetoJson
    },
    setupRoutes() {
      let defaultJson = maps.DefaultLeaflet
      const trajeto = this.setupTrajeto()
      defaultJson.features.push(trajeto)
      return defaultJson
    },
    setupMarkers() {
      L.circle([this.partida.position.lat, this.partida.position.lng], maps.CircleStart).addTo(this.map);
      L.circle([this.destino.position.lat, this.destino.position.lng], maps.CircleDestiny).addTo(this.map);
      L.circle([this.final.position.lat, this.final.position.lng], maps.CircleEnd).addTo(this.map);
    },
    setPlaceMotorista(lat, lng) {
      const carIcon = L.icon({
          iconUrl: require('@/assets/svg/carGreen.svg'),
          iconSize: [20, 40],
      });
      const marker = L.marker([lat, lng], {
          icon: carIcon,
      }).addTo(this.map);
      return marker
    },
    drawTrack() {
      this.setupLeafletMap(this.partida.position);
      this.setupMarkers();

      const bounds = [].concat(
        this.partida.position,
        this.final.position,
        this.trajeto
      )
      this.map.fitBounds(bounds);
    },
    updateRoute() {
      let isOffRoute = true

      const result = this.getPointPositionOnRoute(this.route.coords, this.motorista.position.curr)

      if (result) {
        isOffRoute = false
        this.route.coords.splice(0, result.a.index)
        maps.Draw.clear(this.route.path)
        this.route.path = maps.Draw.route(this.route.coords, this.map)
      }

      if (isOffRoute) {
        this.route.status = this.constants.Route.OFF_ROUTE
      } else {
        clearTimeout(this.route.off.timer)
        this.route.off.timer = null
        this.route.status = this.constants.Route.ON_ROUTE
      }

      this.rerouteHandler()
    },
    computeDistanceBetween(a, b) {
      const R = 6371e3;
      const p1 = this.toRadians(a.lat);
      const p2 = this.toRadians(b.lat);
      const c1 = this.toRadians(b.lat - a.lat);
      const c2 = this.toRadians(b.lng - a.lng);

      const c3 = Math.sin(c1 / 2) * Math.sin(c1 / 2)
        + Math.cos(p1) * Math.cos(p2) * Math.sin(c2 / 2) * Math.sin(c2 / 2);

      const c4 = 2 * Math.atan2(Math.sqrt(c3), Math.sqrt(1 - c3));

      return R * c4;
    },
    getDistanceBetween(a, b, c) {
      const distanceAB = this.computeDistanceBetween(a, b);
      const distanceAC = this.computeDistanceBetween(a, c);
      const distanceBC = this.computeDistanceBetween(b, c);
      const distanceACBC = distanceAC + distanceBC;

      return distanceACBC - distanceAB;
    },
    getPointPositionOnRoute(coords, point) {
      let closest;

      for (let i = 0; i < coords.length; i++) {
        const a = coords[i]

        if (i + 1 < coords.length) {
          const b = coords[i + 1]

          const distance = this.getDistanceBetween(a, b, point);

          if (distance <= this.config.offRouteTolerance) {
            if (typeof closest === 'undefined' || distance < closest.distance) {
              closest = {
                a: { index: i, coordinate: a },
                b: { index: i + 1, coordinate: b },
                c: point,
                distance,
              };
            }
          }
        }
      }

      return closest;
    },
    async rerouteHandler() {

      if (this.route.reroute.status === this.constants.Reroute.SHOULD_REROUTE) {
        this.setRoute((data) => {
          this.route.coords = data
          if (this.route.path) {
            this.route.path.remove();
          }
          this.route.path = this.drawRoute(this.route.coords, this.map)
          this.route.reroute.status = this.constants.Reroute.KEEP_CURRENT_ROUTE
          this.route.reroute.count++
        })
      } else if (this.route.status === this.constants.Route.OFF_ROUTE && !this.route.off.timer) {
        this.route.off.timer = setTimeout(() => {
          this.route.reroute.status = this.constants.Reroute.SHOULD_REROUTE
          this.route.off.timer = null
        }, this.config.rerouteTime)
      }
    },
    polyline(path, props) {
      var polyline = L.polyline(path, props).addTo(this.map);
      return polyline
    },
    drawRoute(coords) {
      const props = {
        geodesic: true,
        color: '#18BC9C',
        strokeOpacity: .7,
        strokeWeight: 5
      }

      const routePath = this.polyline(coords, props)

      return routePath
    },
    linearDistance(start, end, motorista) {
      const m = (end.lng - start.lng) / (end.lat - start.lat)
      const b = start.lng - (m * start.lat)

      return Math.abs(motorista.lng - (m * motorista.lat) - b) / Math.sqrt(Math.pow(m, 2) + 1)
    },
    startStatusPolling() {
      const uid_corrida = this.$route.params.uid_corrida

      this.poll.status.reference = setInterval(async () => {
        try {
          const { status, data } = await this.$http.get(`/api/corrida/${uid_corrida}/status`)

          if (status === 200) {
            if (data) {
              const status = data.status

              this.status = status

              if (status !== 30) {
                this.releaseResources()
                maps.Draw.clear(this.route.path)
              }
            } else {
              console.warn(status, data)
            }
          } else {
            console.error(status, data)
          }
        } catch (err) {
          console.log(err)
        }
      }, this.poll.status.time)
    },
    releaseResources() {
      clearInterval(this.poll.status.reference)
      clearTimeout(this.route.off.timer)

      tracker.untrack(this.tracker)
    }
  },
  async created() {
    await this.setConfig()
    await this.fetchData()
  },
  mounted() {
    this.info.show = !this.$route.query.hasOwnProperty('clean')
  },
  beforeDestroy() {
    this.releaseResources()
  }
}
</script>

<style scoped>
  #map-container,
  #map,
  .container,
  .not-found {
    height: 100%;
  }

  #map-container {
    position: relative;
    width: 100%;
  }

  #map {
    z-index: 100;
  }
</style>


